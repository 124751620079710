@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');
:root {
  --font-family:  "montserrat", sans-serif;
  --gray1 : #595959;
  --gray2 : #7f7f7f;
  --gray3 : #a5a5a5;
  --gray4 : #cccccc;
  --white2 : #f2f2f2;
  --white : #ffffff;
  --yellow: #fbba39;
  --brown : #a18966;
  --blue  : #6ab0e1;
  --blue2  : #3d5ba6;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body{
  font-family: var(--font-family) !important;
  background-color: var(--white2)!important;
}

.btn-anas{
  background-color: var(--blue)!important;
  color: var(--white)!important;
  border-radius: 3px!important;
  font-size: 15px!important;
  font-weight: 500!important;
}

.btn-anas:hover{
  background-color: var(--blue2)!important;
  color: var(--white)!important;
  border-radius: 3px!important;
}


.content{
  position: relative;
  z-index: 9999;
}