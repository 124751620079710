.all {
    padding: 0px 0;
    width: 100%;
    background-color: #f5f5fc;
    --bs-gutter-x: 0!important
}

.home {
    display: flex;
    background-color: var(--white2);
    width: 100%;
}

.slider {
    width: 7.5%;
    min-height: 100vh;
    padding: 5px 0;
    background: var(--blue);
    padding: 120px 0;
    transition: all .4s ease-in-out;
    display: flex;
    height: 100%;
    position: relative;
}

.open,
.close {
    position: fixed;
    top: 3%;
    left: 2.5%;
    width: 25px;
    height: 25px;
    z-index: 9999;
    display: none;
}

.close {
    display: none;
}

.open {
    transform: rotate(90deg);
}

.open svg {
    stroke: var(--blue);
    fill: var(--blue);
    cursor: pointer;
}

.close svg {
    stroke: var(--white);
    fill: var(--white);
    cursor: pointer;
}

.slider .navItem {
    height: 100%;
    width: 100%;
    grid-template-columns: 200px;
    grid-template-rows: repeat(6, 1fr);
    padding: 0px 0 0px 0px;
    position: fixed;
    top: 7%;
    left: 0;
}

.slider .navItem a {
    margin: 0px 0;
    padding: 20px 10px;
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 13px;
    transition: all .1s ease-out;
    display: block;
    width: 7.5%;
    text-align: center;
}

.slider .navItem a {
    border-left: 4px solid var(--blue2);
}

.slider .navItem a:hover {
    background: var(--blue2);
}

.hide .navItem a.active,
.slider .navItem a.active {
    background: var(--blue2);
}

.slider .navItem .googleForm {
    border: none;
    margin-top: 2%;
    padding-left: 33px;
}

.slider .navItem a svg {
    width: 25px !important;
    fill: var(--white);
}

@media (min-width:315px)and (max-width:992px) {
    .open {
        display: block;
    }
    .slider {
        width: 0%;
        position: relative;
    }
    .slider.show {
        width: 100%;
        position: fixed;
        overflow-y: scroll;
        z-index: 99999999;
        display: block;
    }
    .slider .navItem {
        position: static;
        display: none;
    }
    .show .navItem {
        padding: 0;
        display: block;
    }
    .show .navItem a {
        text-align: left;
        width: auto;
        height: 50px;
        display: block;
        padding: 10px;
    }
    .slider.hide {
        transition: none;
        display: none;
    }
    .slider .navItem .googleForm {
        margin-top: 4%;
        padding-left: 15px;
    }
    .show .navItem a svg {
        width: 15%;
        margin-bottom: 5px;
    }
    .show .navItem span {
        font-size: 20px;
        font-weight: 500;
        margin-left: 25px;
        display: inline !important;
    }
    .close {
        top: 4%;
        left: 7%;
        z-index: 9999999999;
    }
    .open {
        top: 4%;
        left: 7%;
        z-index: 9999999999;
    }
    .hide .navItem a.active {
        position: static;
        width: 100%;
    }
    .slider .navItem a.active {
        background: var(--blue2);
        border: unset;
        position: static;
        width: 50%;
        border-radius: 0 0px 0px 0;
        text-align: left;
        padding-left: 15px;
    }
    .slider .navItem a.active {
        width: auto;
    }
    .slider .navItem hr {
        display: block!important;
        width: 80%;
        margin: .5rem auto;
        margin-left: 35px;
        height: 2px;
        background-color: var(--yellow);
        opacity: .9;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .slider.show {
        width: 7%!important;
    }
    .slider .navItem a svg {
        width: 20px!important;
    }
}

@media only screen and (min-width: 1380px) {
    .slider {
        width: 10.5%;
    }
    .slider .navItem a {
        width: 10.5%;
        font-size: 17px;
    }
}