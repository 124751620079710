.ourProject{
    background-color: var(--white2);
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    
    padding: 20px 0;
}
.ourProject .content{
display: block;
width: 90%;
margin: auto;
margin-bottom: 10rem;
}

.ourProject .content h2{
    color: var(--gray1);
    display: block;
    font-weight: 500;
    margin-top: 4rem;
}

.ourProject .content hr{
    width: 7%;
    height: 3px;
    background: var(--yellow);
    border-radius: 1px;
    opacity: 1;
    margin: 10px auto;
    margin-bottom: 30px;

}


.ourProject .content .boxes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ourProject .content .boxes .box{
    width: 33%;
    margin: 0;
    padding: 20px;
    position: relative;
    overflow: hidden;
   
}
.ourProject .content .boxes .box img{
width: 100%;
height: 200px;
object-fit: cover;
object-position: center;
}

.ourProject .content .boxes .box .overlay {
  min-height: 19%;
  height: auto;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  padding: 20px 10px;
  min-height: 120px;
  background-color: var(--white2);
  box-shadow: var(--box-shadow);
}
.ourProject .content .boxes .box .overlay hr{
width: 15%;
margin: 10px;
margin-bottom: 10px;
}



.ourProject .content .boxes .box .overlay .text {
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin: 0;
  text-transform: none;
  text-align: left;

}


@media (max-width:992px) {
    .ourProject .content .boxes .box{
    width: 49%;
    height: 160px;
    }

    .ourProject .content .boxes .box .overlay .text {
font-size: 13px;
width: 95%;
    }


    .ourProject .content .boxes .box .overlay {

  min-height: 50%;

}
}

@media (max-width:577px) {
    .ourProject .content .boxes .box{
    width: 90%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }

    .ourProject .content .boxes .box .overlay .text {
font-size: 13px;
width: 95%;
    }


    .ourProject .content .boxes .box .overlay {

  min-height: 30%;

}
}

@media (min-width:1400px) {
.ourProject .content{
width: 80%;

}
}