.contactUs {
    background-color: var(--white2);
    width: 100%;
    min-height: 70vh;
    padding: 20px 0;
}

.contactUs .content {
    display: block;
    width: 80%;
    margin: auto;
}

.contactUs .content h2 {
    color: var(--gray1);
    display: block;
    font-weight: 500;
    margin-top: 4rem;
}

.contactUs .content p {
    display: block;
    font-weight: normal;
    font-size: 18px;
    color: var(--gray3);
    text-align: center;
    margin-bottom: 30px;
    margin-bottom: 3rem;
}

.contactUs .content .form {
    width: 100%;
    height: auto;
    background: var(--white);
    display: flex;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    margin-bottom: 3rem;
}

.contactUs .content .form .contactInfo {
    height: auto;
    width: 45%;
}

.contactUs .content .form .contactInfo .bgc {
    width: 100%;
    height: 100%;
    background-color: rgba(99, 99, 99, 0.2);
    border-radius: 5px 8px 8px 5px;
    position: relative;
}

.contactUs .content .form .contactInfo .bgc {
    padding: 2rem;
    text-align: justify;
}

.contactUs .content .form .contactInfo .bgc h2 {
    color: var(--blue);
    margin-bottom: 2rem;
    font-weight: bold;
    margin-top: 0;
}

.contactUs .content .form .contactInfo .bgc p {
    text-align: justify;
    color: var(--blue);
}

.contactUs .content .form .contactInfo .bgc span {
    display: block;
    margin-bottom: 2.5rem;
    color: var(--blue);
}

.contactUs .content .form .contactInfo .bgc svg {
    width: 5%;
    fill: var(--blue);
}

.contactUs .content .form .contactInfo .bgc button {
    position: absolute;
    bottom: 3%;
    right: 50%;
    transform: translateX(50%);
    /* width: 50%; */
    padding: 10px;
    white-space: nowrap;
}

.contactUs .content .form .contactInfo .bgc button a {
    text-decoration: none;
    color: #fff;
}

.contactUs .content .form .sendMessage {
    width: 65% !important;
    background-color: #fff;
    border-radius: 8px;
    height: auto;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 4rem;
}

.contactUs .content .form .sendMessage form {
    display: block;
    width: 100%;
}

.contactUs .content .form .sendMessage .input-group {
    width: 45%;
    display: inline-flex;
    margin: 3rem 2% 2rem 2%;
    border: unset;
}

.contactUs .content .form .sendMessage form .form-control,
.contactUs .content .form .sendMessage form .form-control:focus {
    border-top: unset;
    border-right: unset;
    border-left: unset;
    box-shadow: none;
    border-bottom: 2px solid var(--gray3);
    border-radius: 0;
}

.contactUs .content .form .sendMessage form .form-control:focus {
    border-color: var(--yellow)!important;
    color: var(--yellow);
    border-radius: 0;
    font-weight: 500;
    font-size: 15px;
}

.contactUs .content .form .sendMessage .input-group label {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--gray1);
}

.contactUs .content .form .sendMessage .input-group.textarea1 {
    width: 95%!important;
}

.contactUs .content .form .sendMessage {
    position: relative;
}

.contactUs .content .form .sendMessage form button {
    position: absolute;
    bottom: 5%;
    right: 6%;
    width: 30%;
    padding: 10px;
}

@media (min-width:315px) and (max-width:375px) {
    .contactUs .content .form .contactInfo .bgc button {
        /* width: 80%; */
        white-space: nowrap;
        width: 90% !important;
    }
    .form .contactInfo {
        width: 100% !important;
        height: 582px;
    }
    .contactUs .content .form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .contactUs .content .form .sendMessage,
    .contactUs .content .form .contactInfo {
        width: 100% !important;
        /* height: 500px; */
    }
    .contactUs .content .form .sendMessage {
        padding-top: 0;
    }
    .contactUs .content .form .sendMessage form .form-control {
        width: 100%;
    }
    .contactUs .content .form .sendMessage .input-group {
        width: 100%;
        display: block;
        margin: 2rem auto;
    }
    .contactUs .content .form .sendMessage .input-group textarea {
        margin-bottom: .5rem;
    }
    .contactUs .content .form .contactInfo .bgc button {
        width: 70%;
    }
    .contactUs .content .form .sendMessage form button {
        right: 12%;
        font-size: 15px;
        width: 70%;
        display: block;
        margin: auto;
        position: static;
    }
    .contactUs .content .footer {
        margin-bottom: 2rem;
    }
    .contactUs .content .footer p {
        margin-bottom: 0;
    }
}

@media (min-width:376px) and (max-width:576px) {
    .contactUs .content .form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .contactUs .content .form .sendMessage,
    .contactUs .content .form .contactInfo {
        width: 100% !important;
        height: 500px;
    }
    .contactUs .content .form .sendMessage {
        padding-top: 0;
    }
    .contactUs .content .form .sendMessage form .form-control {
        width: 100%;
    }
    .contactUs .content .form .sendMessage .input-group {
        width: 100%;
        display: block;
        margin: 2rem auto;
    }
    .contactUs .content .form .sendMessage .input-group textarea {
        margin-bottom: .5rem;
    }
    .contactUs .content .form .contactInfo .bgc button {
        width: 70%;
    }
    .contactUs .content .form .sendMessage form button {
        right: 12%;
        font-size: 15px;
        width: 70%;
        display: block;
        margin: auto;
        position: static;
    }
    .contactUs .content .footer {
        margin-bottom: 2rem;
    }
    .contactUs .content .footer p {
        margin-bottom: 0;
    }
}

@media (min-width:577px) and (max-width:992px) {
    .contactUs {
        align-items: center;
    }
    .contactUs .content .form .sendMessage .input-group.textarea1 {
        width: 79%!important;
    }
    .contactUs .content .form .contactInfo .bgc {
        height: auto;
        padding: 6rem;
        padding-top: 3rem;
    }
    .contactUs .content .form .contactInfo .bgc span {
        margin-bottom: 3rem;
    }
    .contactUs .content .form {
        flex-wrap: wrap;
        border-radius: 8px;
        width: 100%;
    }
    .contactUs .content .form .sendMessage {
        width: 100% !important;
    }
    .contactUs .content .form .contactInfo {
        width: 100%;
    }
    .contactUs .content .form .sendMessage form .form-control {
        width: 100%;
    }
    .contactUs .content .form .sendMessage .input-group {
        width: 80%;
        display: block;
        margin: 2rem auto;
    }
    .contactUs .content .form .sendMessage .input-group textarea {
        margin-bottom: 4rem;
    }
    .contactUs .content .form .sendMessage form button {
        right: 12%;
        font-size: 15px;
        width: auto;
    }
}

@media (min-width:1400px) {
    .contactUs {
        min-height: 80vh;
    }
    .contactUs .content h2 {
        margin-top: 0;
    }
    .contactUs .content .form {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        min-height: 60vh;
        margin-bottom: 0;
    }
    .contactUs .content .form .contactInfo .bgc p {
        margin-bottom: 2rem;
    }
    .contactUs .content .form .contactInfo .bgc span {
        margin-bottom: 3.5rem;
        font-size: 20px;
        font-weight: 400;
    }
    .contactUs .content .form .contactInfo .bgc span:last-child {
        margin-bottom: 5rem;
    }
    .contactUs .content .form .sendMessage form button,
    .contactUs .content .form .contactInfo .bgc button {
        bottom: 6%;
    }
    .contactUs .content .form .contactInfo .bgc h2 {
        margin-bottom: 3rem;
    }
    .contactUs .content .form .sendMessage .input-group {
        margin: 4rem 2% 2rem 2%;
    }
}