.whoWeAre{
    background-color: var(--white2);
    width: 100%;
    min-height: 100vh;
    padding: 20px 0;
}

.whoWeAre .content{
    display: block;
    width: 80%;
    margin: auto;
    margin-top: 3rem;
}
.whoWeAre .content hr{
    width: 7%;
    height: 3px;
    background: var(--yellow);
    border-radius: 1px;
    opacity: 1;
    margin: 20px auto;
}
.whoWeAre .content h2{
    margin-top: 3rem;
    color: var(--gray1);
}

.whoWeAre .content .form{
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
}

.whoWeAre .content .form .left{
 width: 35%;
 background-color: var(--blue);  
 padding: 2rem 2rem;
 color: #333;
}

.whoWeAre .content .form .left p{
font-size: 16px;
font-weight: 500;
display: block;
text-align: left;
color: #333;
}

.whoWeAre .content .form .left hr{
    width: 15%;
    margin: 1rem 0;
}

.whoWeAre .content .form .left p:last-of-type{
margin-bottom: 0;
}


.whoWeAre .content .form .right{
width: 65%;
background-color: var(--gray4);
position: relative;
}
.whoWeAre .content .form .right .img {
    width: 100%;
    object-fit: cover;
}


.whoWeAre .content .form .right img{
    width:100%;
    height: 100%;

}

.whoWeAre .content .form .right .textf{
width: 100%;
position: absolute;
bottom: 4.8%;
}

.whoWeAre .content .form .right .textf p{
font-size: 16px;
color: #333;
font-weight: 500;
padding: 0rem 2rem;
padding-top: 2rem;
margin: 0;

}


.whoWeAre .content .form .right svg{
    position: absolute;
    width: 15%;
    z-index: 1000;
    bottom: 90%;
    right: 0%;
    fill:rgba(51, 51, 51, 0.451)
}


.whoWeAre .content .new-box{
    margin-bottom: 2rem;
    width: 100%;
    background-color: var(--yellow);
    display: flex;
}

.whoWeAre .content .new-box p{
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: #333;
    padding-left: 30px;
    padding: 15px;
    padding-bottom: 0;
}
.whoWeAre .content .new-box a{
    margin-left: auto;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px;
    background-color: var(--gray3);
    color: var(--white);
    font-weight: bold;
    padding-bottom: 0;
    position: relative;
    transition: all .3s ease-in-out;
    z-index: 999999999999999;
}
.whoWeAre .content .new-box a:hover{
    background-color: var(--gray2);

}
@media (max-width:850px) {
    .hoWeAre .content{
        width: 100%;
    }
    .whoWeAre .content .form{
  flex-wrap: wrap;
  width: 80%;
  margin-inline: auto;

}
 .whoWeAre .content .footer{margin-bottom: 3rem;}
.whoWeAre .content .form .right .img .pos {
display: none;
}
.whoWeAre .content .form .right,
.whoWeAre .content .form .left
{
    width: 100%;
    border: unset;
    box-shadow: none;
}

.whoWeAre .content .form .left
{
    height: auto;
}
.whoWeAre .content .form .left p{
    padding: 0;
    line-height: 2;
    font-size: 14px;
    text-align: left;
    line-break: auto;
    margin-bottom: 20px;
}
.whoWeAre .content .form .left p:last-child{
    margin-bottom: 0px;
}

.whoWeAre .content .form .right{
min-height: 300px;
}




.whoWeAre .content .form .right img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
.whoWeAre .content .form .right .textf{
    min-height: 100%;
    top: 0;
    background-color: rgba(51, 51, 51, 0.451);
    display: flex;
    align-items: center;
}

.whoWeAre .content .form .right .textf p{
    color: #fff;
    padding: 15px 20px;
    font-size: 13px;
    font-weight: 400;

}
.whoWeAre .content .new-box{
    width: 80%;
    margin: auto;
}
.whoWeAre .content .new-box p{
    font-size: 13px;
    font-weight: bold;
}
.whoWeAre .content .new-box a{
    font-size: 13px;
}
}





@media (min-width:1400px) {
    .whoWeAre .content .form{
        width: 100%;
        margin-inline: auto;
        margin-top: 5rem;
      
      }
      .whoWeAre .content .form .left{
        width: 30%;
        padding-block: 4rem;
      }
      .whoWeAre .content .form .left p{
        font-size: 21px;
        font-weight: 400;
      }
      .whoWeAre .content .form .left hr{
      margin: 2rem 0;
        }

      .whoWeAre .content .form .right{
        width: 70%;
      }
      .whoWeAre .content .form .right .textf p{
        font-size: 21px;
        font-weight: 400;
    }
    .whoWeAre .content .new-box p,
    .whoWeAre .content .new-box a{
        font-size: 21px;
        font-weight: 500;
    }
}

