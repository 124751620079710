.ourService{
    background-color: var(--white2);
    width: 100%;
    min-height: 100vh;
    padding: 20px 0;
}
.ourService .content{
display: block;
width: 90%;
margin: auto;
}

.ourService .content h2{
    color: var(--gray1);
    display: block;
    font-weight: 500;
    margin-top: 3rem;
}

.ourService .content hr{
    width: 7%;
    height: 3px;
    background: var(--yellow);
    border-radius: 1px;
    opacity: 1;
    margin: 10px auto;
    margin-bottom: 3rem;

}



.ourService .content .boxes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

}

.ourService .content .boxes .box{
    width: 33%;
    padding: 10px;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;

}
.ourService .content .boxes .box img{
width: 100%;
}

.ourService .content .boxes .box .overlay {
width: 100%;
overflow:hidden;
}

.ourService .content .boxes .box .overlay .text {
  color: #333;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  margin: 0;
  text-transform: none;
  text-align: left;
  padding: 1px 0;
  margin-top: 5px;

}

.ourService .content .boxes .box .overlay .text span{
    font-weight: bold;
    
}

.ourService .content .boxes .box .overlay .text  .hr{
height: 3px;
width: 20%;
margin-bottom: 5px;
margin-top: 5px;
margin-left: 0;
display: block;
background: var(--yellow);
border-radius: 1px;
}




@media (max-width:577px) {
    .ourService .content{
        width: 100%;
    }
    .ourService .content h2{
    margin-top: 0;
    }
    .ourService .content .boxes{
        width: 80%;
        margin-inline: auto;
    }
    .ourService .content .boxes .box{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    min-height: auto;
    }
.ourService .content p{
    margin:2rem auto;
    width: 90%;
}
    .ourService .content .boxes .box .overlay .text {
font-size: 13px;
    }

.ourService .content .boxes .box img{
    height: auto;
    object-fit: contain;
}


}

@media (min-width:578px) and (max-width:1200px) {
    .ourService .content .boxes .box{
    width: 45%;
    margin: 10px;
    height: auto;
    }
.ourService .content p{
    margin-bottom: 3rem;
}
    .ourService .content .boxes .box .overlay .text {
font-size: 13px;
    }


}




@media (min-width:1400px) {
    .ourService .content h2{
        margin-top: 6rem;
    }

    .ourService .content .boxes {
        margin-top: 6rem;
    }





}