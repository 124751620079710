.logo {
    position: fixed;
    width: 100%;
    top: 0%;
    left: 7.5%;
    z-index: 99999999999;
    background-color: #f2f2f2;
    height: 70px;
    padding: 1rem;
}

.logo img {
    width: 10%;
}

@media (min-width:315px)and (max-width:992px) {
    .logo {
        position: absolute;
        width: 20%;
        left: unset;
        right: 3%;
        height: auto;
        padding: 0;
        top: 3.5%;
    }
    .logo img {
        width: 100%;
    }
}

@media (min-width:1400px) {
    .logo {
        position: fixed;
        width: 100%;
        left: 10.5%;
        z-index: 99999;
    }
}