.footer{
    display: flex;
    width: 100%;
    color: var(--gray4);
    font-weight: 500;
    text-align: center;
    opacity: .5;
    cursor: pointer;
    padding: 20px 0 10px 0;
}
.footer p{
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-size: 16px!important;
    transition: all .3s ease;



}
.footer:hover{
    opacity: 1;
}
.footer span{
    color: var(--yellow);
    margin-inline: 7px ;
}


.footer .icon {
float: right;
}

.footer .icon svg{
    width: 25px;
    fill: var(--gray4);
    margin-right: 5px;
}
.footer .icon svg:hover{
    fill: var(--gray2);
}


@media only screen and (max-width:600px) and (min-width: 315px) { 
.footer{
    margin-bottom: 1rem;
    height: auto;
}
.footer p{
font-size: 12px !important;
text-align: center!important;
align-items: start;
}
.footer .dash{
    display: none
}


.footer br{
    display: block !important;
}

.footer .icon {
display: flex;
justify-content: space-evenly;
float: unset;
margin: 0;
margin-top: 5px;
}
.footer .icon svg{
    width: 20px;
}

}


@media only screen and (max-width:768px) and (min-width: 601px) { 
.footer p{
font-size: 15px;
text-align: justify;
}

.footer .icon {
display: flex;
justify-content: space-evenly;
float: unset;
margin: 0;
margin-top: 5px;
}
.footer .icon svg{
    width: 30px;
}

}

@media (min-width:1400px) {
.footer{
    display: flex;
    align-items: end;
    margin-top: 2rem;
}
.footer p{
    font-size: 20px!important;
    margin-bottom: 1rem;

}

    .footer .icon svg{
    width: 30px;
}
}

