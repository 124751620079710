.whyUs{
background-color: var(--white2);
width: 100%;
min-height: 120vh;
display: flex;
justify-content: center;
padding: 20px 0;
}
.whyUs .content{
display: block;
width: 80%;
margin: auto;
}

.whyUs .content h2{
    color: var(--gray1);
    display: block;
    font-weight: 500;
}

.whyUs .content hr{
    width: 7%;
    height: 3px;
    background: var(--yellow);
    border-radius: 1px;
    opacity: 1;
    margin: 10px auto;
}
.whyUs .content p{
    display: block;
    font-weight: normal;
    font-size: 18px;
    color: var(--gray3);
    text-align: center;
    margin-bottom: 30px;
}

.whyUs .content .boxes{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.whyUs .content .boxes .box{
width: 25%;
height: 150px;
position: relative;
overflow: hidden;
cursor: pointer;
}

.whyUs .content .boxes .box img{
    width: 100%;
    object-fit: contain;
}


.whyUs .content .boxes .box .rgba{
width: 100%;
background-color:rgba(98 171 219 / 40%);
position: absolute;
z-index: 999;
height: 160px;
top: 0;
display: flex;
justify-content: center;
align-items: flex-end;
padding: 10.5rem 0 0 0 ;

}
.whyUs .content .boxes .box .rgba p{
    color: var(--white);
    font-weight: 500;
    font-size: 20px;

}

.whyUs .content .boxes .box .icon{
    width: 40%;
    position: absolute;
    right: 50%;
    bottom: 50%;
    z-index: 9999;
    transform: translate(50%, 50%);
}


.whyUs .content .info{
     display: flex;
    width: 100%;
    margin-top: 2rem;
    border-top: 1px solid var(--gray4);
    margin-bottom: 2rem;
    flex-wrap: wrap;
} 

.whyUs .content .info .texts{
    width: 70%;
    padding: 2rem 2rem 0rem 0;
}

.whyUs .content .info .texts p{
    text-align: justify;
     display: block;
    font-weight: 500;
    font-size: 17px;

    color: var(--gray1);

    }


.whyUs .content .info .pic{
    width: 30%;
    margin-top: 2rem;
}


.whyUs .content .info .pic .box{
position: relative;
width: 100%;
height: 100%;
overflow: hidden;
}
.whyUs .content .info .pic .box img
{
        width: 100%;
        height: 100%;
    object-fit: cover;
    object-position: right;

}





.whyUs .content .info .pic .box .rgba{
width: 100%;
background-color:rgba(98 171 219 / 30%);
position: absolute;
z-index: 999;
height: 100%;
top: 0;
display: flex;
justify-content: center;
align-items: flex-end;
padding: 5rem 0 0 0 ;

}
.whyUs .content .info .pic .box .rgba p{
    color: #3e4549;
    font-weight: 500;
    font-size: 20px;

}

.whyUs .content .info .pic .box .icon{
    width: 50%;
    height: 50%;
    position: absolute;
    right: 50%;
    bottom: 50%;
    z-index: 9999;
    transform: translate(50%, 50%);
}
.whyUs .content .footer{
    align-items: start;
    margin-top: 1rem;
}


@media (max-width:576px) {
    .whyUs{
        display: block;
    }
.whyUs .content {
    height: auto;
    margin-bottom: 0rem;
    width: 80%;
}


.whyUs .content h2{
font-size: 18px;
margin-top: 2rem;
}


.whyUs .content p{
font-size: 13px;
}


.whyUs .content .info .pic .box .rgba p{
font-size: 14px;
}

.whyUs .content .boxes{
justify-content: space-evenly;
}


.whyUs .content .boxes .box{
    width: 46%;
    height: 70px;
    margin-bottom: 7px;
}

.whyUs .content .info{
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 0;

}
.whyUs .content .info .texts{
    width: 100%;
    padding-right: 0;

}

.whyUs .content .info .pic{
    margin-top: 0;
    width: 100%;
    height: 10vh;
}
.whyUs .content .info .pic .box{
    height: auto;
}
.whyUs .content .info .footer{
    margin-top: 2rem;
    margin-bottom: 0;
}
}


@media (max-width:992px) {
 
.whyUs .content h2{
font-size: 18px;
margin-top: 2rem;
}


.whyUs .content p{
font-size: 13px;
}


.whyUs .content .info .pic .box .rgba p{
font-size: 14px;
}

.whyUs .content .boxes{
justify-content: space-evenly;
}


.whyUs .content .boxes .box{
    width: 46%;
    height: 70px;
    margin-bottom: 7px;
}

.whyUs .content .info{
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;

}
.whyUs .content .info .texts{
    width: 100%;

}

.whyUs .content .info .pic{
    margin-top: 0;
    width: 100%;
        height: auto;

}
.whyUs .content .footer {
margin-bottom: 3rem;
}
.whyUs .content .info .pic .box img{
        object-position: top;
}
.whyUs .content .info .pic .box .icon{
    width: 40%;
    height: 100%;
}

}


@media (min-width:1400px) {
.whyUs{
background-color: var(--white2);
width: 100%;
min-height: 100vh;
display: flex;}

.whyUs .content h2{
    margin-top: 3rem;
}

.whyUs .content .boxes{
    margin-top: 6rem;
}

.whyUs .content .info .texts p{
    font-size: 21px;
    font-weight: 500;
}

.whyUs .content .boxes .box .icon{
bottom: 55%;
width: 33%;
}

}
