
.carousel .carousel-inner{
    position: relative;
}

.carousel .carousel-inner .carousel-caption{

padding: 2rem 0;
background-color: rgba(0, 0, 0, 0.6);
}

.carousel.slide img{
    height: 100vh;
}
.carousel-control-prev-icon{
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid white;
    margin-right: 2rem;
}

.carousel-control-next-icon{
        width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid white;
}
.carousel i{
    color: #39393b;
    font-size: 35px;
}

@media only screen and (max-width: 600px) and (min-width: 315px)  {
.carousel.slide img{
    height: 100vh;
    object-fit: cover;
}

.carousel .carousel-inner .carousel-caption h3,
.carousel .carousel-inner .carousel-caption p span.h4
{
    font-size: 19px;
}
.carousel .carousel-inner .carousel-caption p span.sm-size{
   display: none!important;
}

}