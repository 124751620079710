.ourClients{
    background-color: var(--white2);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.ourClients .content{
display: block;
width: 80%;
margin: auto;

}
.ourClients .content h2{
    color: var(--gray1);
    display: block;
    font-weight: 500;
    margin-bottom: 30px;

}
.ourClients .content hr{
    width: 7%;
    height: 3px;
    background: var(--yellow);
    border-radius: 1px;
    opacity: 1;
    margin: 20px auto;
}

.ourClients .boxes{
display: flex;
align-items: center;
justify-content: center;
}

.ourClients .footer {
align-items: unset;
margin-top: 2rem;
}


.ourClients .boxes .box{
    height: 150px;
    background-color: #fff;
    margin: 15px;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    overflow: hidden;
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ourClients .boxes .box img {

    width: 80%;
}

.ourClients .boxes .box .img5 {width: 100%;}
.ourClients .boxes .box .img7 {width: 60%;}
/* .ourClients .boxes .box .img1 {width: 45%;}
.ourClients .boxes .box .img2 {width: 40%; margin-top: 8%;}
.ourClients .boxes .box .img3 {width: 38%;}
.ourClients .boxes .box .img4 {width: 55%; height: 55%; margin-top: 10%;}
.ourClients .boxes .box .img6 {width: 40%;}
 */

.ourClients .boxes .box .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color:rgb(251 186 57 / 93%);;
}


.ourClients .boxes .box:hover .overlay {
  opacity: 1;
}

.ourClients .boxes .box:hover .overlay .text {
  color: white;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


.ourClients .boxes.dflex{
    width: 77%;
    margin: auto;
    margin-bottom: 20px;
}
.ourClients .boxes .box{
width: 30%;
}

.ourClients button{
    margin: auto;
}
.ourClients  .footer{
    margin-top: 1rem;
    margin-bottom: 3rem;
    height: auto;
    width: 80%;
}

@media (min-width:315px) and (max-width:992px) {
  
.ourClients .content h2{
   margin-bottom: 10px;
   font-size: 1.5rem;
}

.ourClients .content p {
   margin-bottom: 10px;
   font-size: 13px;
}


.ourClients .boxes,
.ourClients .boxes.dflex{
display: flex;
flex-wrap: wrap;
width: 100%;
}

.ourClients .boxes .box,
.ourClients .boxes.dflex .box{
width: 80%;
margin: 10px 10px 0px 0!important;
height: 120px;
 }
.ourClients .boxes .box .img1 ,
.ourClients .boxes .box .img2, 
.ourClients .boxes .box .img3 ,
.ourClients .boxes .box .img4 ,
.ourClients .boxes .box .img5 ,
.ourClients .boxes .box .img6 ,
.ourClients .boxes .box .img7 
{object-fit: contain; width: 120px; height: 100px;margin: 0 auto; }



.ourClients button{
width: 47%!important;
}
}


@media (min-width:1400px){
.ourClients h2{
    margin-top: 5rem;
}
.ourClients .content hr{
    margin-bottom: 4rem;
}
.ourClients .boxes .box {
margin-bottom: 4rem;
height: 220px;
}


/* .ourClients .boxes .box .img2 {width: 40%; margin-top: 5%;}
.ourClients .boxes .box .img3 {width: 38%; margin-top: 1%;}
.ourClients .boxes .box .img4 {width: 55%; height: 55%; margin-top: 8%;} */

}